import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { ACTION_CONST, LIMIT_VALUE } from '../../constants';
import { helpers } from '../../utils/helpers';
import { BigNumber } from "bignumber.js";
export default function AmountInputPanel({ label, onAmountChange, network, token, isClearAmount , pauseSwap}) {

  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const balance = useSelector((state) => get(state, 'wallet.assetBalance', 0));

  const isConnectWallet = useSelector((state) => get(state, 'utils.isConnectWallet', false));


  const walletUtils = useSelector((state) => get(state, 'utils.walletUtils', null));
  const showBlockUI = useSelector((state) => get(state, "utils.blocking", false));

  useEffect(() => {
   
    if (walletUtils && token.contractAddress) {
      walletUtils.getTokenBalance({
        tokenAddress: token.contractAddress,
        network: network.id,
        decimal: token.decimals
      }).then(data => {
        setTokenBalance(data);
        dispatch({ type: ACTION_CONST.GET_ASSET_BALANCE_SUCCESS, data: data })
      })


      const jobGetAssetBalance = setInterval(() => {
        walletUtils.getTokenBalance({
          tokenAddress: token.contractAddress,
          network: network.id,
          decimal: token.decimals
        }).then(data => {
          setTokenBalance(data)
          dispatch({ type: ACTION_CONST.GET_ASSET_BALANCE_SUCCESS, data: data })
        })
      }, 10 * 1000)

      dispatch({
        type: ACTION_CONST.SET_JOB_GET_ASSET_BALANCE,
        data: jobGetAssetBalance
      });
    }
  }, [walletUtils, token, showBlockUI])


  const handleInputAmountChange = (e) => {
    if (isConnectWallet) {
      const value = e.target.value;

      if (Number(value) > tokenBalance) {
        handleMaxButtonClick();
        return;
      }

      if (helpers.validateAmountInput(value)) {

        const temBalance = helpers.formatNumberDownRoundWithExtractMax(Number(value), 4)
        setAmount(temBalance);
        onAmountChange(temBalance);
        return;
      }
      // const temBalance = helpers.formatNumberDownRoundWithExtractMax(value, 4)
      setAmount(value);
      onAmountChange(value);
    }
  };


  const handleMaxButtonClick = () => {
    if (walletUtils) {
      let { swapped, limit } = token;
      
       //check address is admin token
      if(limit === 0 ) limit = LIMIT_VALUE.MAX;

      const tempBalance = helpers.formatNumberDownRoundWithExtractMax(Number(tokenBalance), 4)

      const max = limit - swapped;
      const bigMax = BigNumber(`${max}`);
      const bigBalance = BigNumber(`${tempBalance}`)


      if (bigBalance.lte(bigMax)) {
        setAmount(Number(tempBalance))
        onAmountChange(Number(tempBalance));
        return 
      }
      if(bigBalance.gte(bigMax)){
        setAmount(Number(max))
        onAmountChange(Number(max));
        return 
      }
    }
  }


  useEffect(() => {
    if (isClearAmount) {
      setAmount(0)
    }

  }, [isClearAmount])

  return (
    <>
      <div className="p-form-group mb-2">
        <div className="mb-2 d-flex align-items-center justify-content-between">
          <label className="form-label p-main-text mb-0">{label}</label>
          <span className="font-14 me-2 text-warning">Available: <b>{helpers.formatNumberDownRoundWithExtractMax(balance, 4)} {token.symbol}</b></span>
        </div>
        <div className="p-input-group">
          <input
            type="number"
            className="form-control px-0"
            placeholder="0"
            value={amount}
            onChange={(e) => handleInputAmountChange(e)}
            disabled={!isConnectWallet}
          />
          <Button
            onClick={handleMaxButtonClick}
            disabled={!isConnectWallet || pauseSwap || token.pause}
          >MAX</Button>
        </div>
        <hr className="mb-1" />
        <div className="d-flex align-items-center text-nowrap justify-content-between py-2">
          <span className="font-13"><b>Daily Swapped / Limit</b></span>
          <span className="font-13"><b>{`${token.swapped || 0}/${token.limit===0? `∞`: token.limit || 0}`}</b> {token.symbol}</span>
        </div>
      </div>

    </>
  );
}
