import React from 'react';

export default function AssetToken({ token, selected, onClickSelect}) {
    return (
        <>
            <div className="c-list border-b px-3 py-2 d-flex align-items-center" data-bs-dismiss="modal" onClick={()=>onClickSelect(token)}>
                <img src={`${token.image}`} width="30px" className="me-3" />
                <div className="text-selected">{token.symbol}<i className={token.symbol === selected? `ms-3 mdi mdi-check text-warning`:`ms-3 mdi text-warning` }></i></div>
            </div>

        </>
    );
}
