import React, { useEffect, useState } from 'react';

export default function NetworkInputPanel({
    label,
    networkList,
    selectedNetwork,
    onNetworkChange,
    type,
    connected
}) {
    const [currentNetwork, setCurrentNetwork] = useState(selectedNetwork);

    useEffect(() => {
        setCurrentNetwork(selectedNetwork);
       
    }, [selectedNetwork]);

    const handleNetworkChange = (network) => {
        setCurrentNetwork(network);
        onNetworkChange(network);
    };

    // console.log("connected==>", connected);
    // console.log("currentNetwork==>", currentNetwork.id);
    return (
        <>
            <div className="p-form-group mb-3">
                <label className="form-label p-main-text">{label}</label>
                <div className="p-network" data-bs-toggle="modal" 
                data-bs-target={connected?"#":`#selectNetworkModal_${type}`}
                >
                    {currentNetwork.badge && (
                        <span className="badge p-network-tag">
                            {currentNetwork.badge}
                        </span>
                    )}
                    {
                        (connected.id ===currentNetwork.id) && <span className="text-connected">Connected</span>
                    }
                    <img
                        height="36"
                        src={`/images/networks/${currentNetwork.id}-icon.svg`}
                    />
                    <div className="p-network-name">
                        <span>{currentNetwork.name}</span>
                        <div className="">
                            <a className="btn btn-sm btn-select-network opacity-08">
                                <i className="mdi mdi-chevron-right font-20"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade in" id={`selectNetworkModal_${type}`} tabIndex="-1" aria-labelledby={`selectNetworkModal_${type}Label`} >
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title text-dark" id={`selectNetworkModal_${type}Label`}>Select Network</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            {networkList.map((value) => {
                                return (
                                    <div key={value.id} onClick={() => handleNetworkChange(value)} className="c-list border-b px-3 py-2 d-flex align-items-center" data-bs-dismiss="modal">
                                        <img src={`/images/networks/${value.id}-icon.svg`} width="26px" className="me-3" />
                                        { value.name === currentNetwork.name ?
                                            <div className="text-selected">{value.name}<i className="ms-2 mdi mdi-check text-warning"></i></div> :
                                            <div>{value.name}</div>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
