
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import BlockUi from "react-block-ui"
import "react-block-ui/style.css";
import { get } from 'lodash';
import { ACTION_CONST, MESSAGES, STATUS } from '../../constants';
import { MODE, SWAP_SMART_CONTRACT, EXPLORER } from '../../_configs';
import { CHAIN_IDS } from '../../constants';
import { getStatusSwap } from '../../redux/services/bridge.api';
import SwapLineStepTab from './SwapLineStepTab';
import SwapStepContent from './SwapStepContentTab';


const StepModal = ({ amount, token, inputNetwork, outputNetwork, destination, clearAmount, handleSwapped }) => {
    const dispatch = useDispatch();

    const showBlockUI = useSelector((state) =>
        get(state, "utils.blocking", false)
    );

    const walletUtils = useSelector((state) =>
        get(state, 'utils.walletUtils', null)
    );


    const [currentStep, setCurrentStep] = useState(0);
    const [step, setStep] = useState(1);
    const [receiptHash, setReceiptHash] = useState("");
    const [status, setStatus] = useState("pending");






    //handle approve button
    const handleApproveStep = async () => {

        const bridgeNetwork = `${inputNetwork.id}_${outputNetwork.id}`.toLocaleUpperCase();
        // console.log("bridgeNetwork==>", bridgeNetwork);
        if (!walletUtils) return;

        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT,
        });

        const allowance = await walletUtils.getAllowance(token.contractAddress, SWAP_SMART_CONTRACT[bridgeNetwork][MODE]);
        if (Number(allowance) >= Number(amount)) {
            setStep(3);
            setCurrentStep(2);
            dispatch({
                type: ACTION_CONST.REQUEST_DONE,
            });
            return;
        }

        // call approve smart contract
        // console.log(SWAP_SMART_CONTRACT[bridgeNetwork],);


        walletUtils.approveToken(
            {
                tokenContractAddress: token.contractAddress,
                spenderAddress: SWAP_SMART_CONTRACT[bridgeNetwork][MODE],
                amount: Number(amount),
                inputNetWork: inputNetwork.id,
                decimal: token['decimals']
            },
            (data) => {
                if (data.status === STATUS.APPROVED) {
                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: MESSAGES.APPROVE_ASSET_SUCCESS,
                    });
                    setStep(3)
                    setCurrentStep(2)
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                }
                if (data.status === STATUS.APPROVE_FAILS) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: MESSAGES.APPROVE_ASSET_FAIL,
                    });
                }
            }
        );
    }

    const handleSwapClickStep = () => {
        if (!walletUtils) return;
        const bridgeNetwork = `${inputNetwork.id}_${outputNetwork.id}`.toLocaleUpperCase();
        // console.log("bridgeNetwork==>", bridgeNetwork);

        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT,
        });


        switch (bridgeNetwork) {
            case "BSC_TRX":
                walletUtils.swapBSCToTron(
                    {
                        amount: Number(amount),
                        tokenAddress: token.contractAddress,
                        toAddress: destination,
                        decimal: token['decimals'],
                        fee: token.fee
                    },
                    (result) => {
                        if (result.status === STATUS.SWAP_SUCCESS) {
                            dispatch({
                                type: ACTION_CONST.REQUEST_DONE,
                            });

                            //set value 0 

                            setStep(4)
                            setCurrentStep(3)
                            dispatch({
                                type: ACTION_CONST.REQUEST_DONE,
                            });

                            handleSwapped(token.contractAddress)
                            getStatus(inputNetwork.id, result.txID);

                            //alert notify with link
                            dispatch({

                                type: ACTION_CONST.ALERT_LINK,
                                alertType: "success",
                                alertUrl: `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/tx/${result.txID}`,
                                alertMessage: `Swap successfully with txID ${result.txID}`,
                            })
                        }
                        if (result.status === STATUS.SWAP_FAILS) {
                            dispatch({
                                type: ACTION_CONST.REQUEST_DONE,
                            });
                            dispatch({
                                type: ACTION_CONST.ALERT_FAILS,
                                message: MESSAGES.SWAP_FAIL,
                            });
                        }
                    }
                );

                break;
            case `BSC_ETH`:
                //code here
                break;
            case `TRX_BSC`:

                walletUtils.swapTronToBSC(
                    {
                        amount: Number(amount),
                        tokenAddress: token.contractAddress,
                        toAddress: destination,
                        decimal: token['decimals'],
                        fee: token.fee
                    },
                    (result) => {
                        if (result.status === STATUS.SWAP_SUCCESS) {
                            dispatch({ type: ACTION_CONST.REQUEST_DONE });

                            //set value 0 

                            setStep(4)
                            setCurrentStep(3)
                            dispatch({
                                type: ACTION_CONST.REQUEST_DONE,
                            });

                            handleSwapped(token.contractAddress)
                            getStatus(inputNetwork.id, result.txID);

                            //alert notify with link
                            dispatch({

                                type: ACTION_CONST.ALERT_LINK,
                                alertType: "success",
                                alertUrl: `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/#/transaction/${result.txID}`,
                                alertMessage: `Swap successfully with txID ${result.txID}`,
                            })
                        }
                        if (result.status === STATUS.SWAP_FAILS) {
                            dispatch({
                                type: ACTION_CONST.REQUEST_DONE,
                            });
                            dispatch({
                                type: ACTION_CONST.ALERT_FAILS,
                                message: MESSAGES.SWAP_FAIL,
                            });
                        }
                    }
                );
                //code here
                break;
            case `TRX_ETH`:
                //code here

                break;

            case `ETH_BSC`:
                //code here
                break;

            case `ETH_TRX`:
                //code here
                break;


            default:
                break;
        }
    }

    //get status button
    const getStatus = (network, txID) => {
        const job = setInterval(() => {
            getStatusSwap(network, txID).then(data => {

                if (data) {
                    // console.log(data);
                    setReceiptHash(data["receiptHash"]);
                    setStatus(data["status"].toLowerCase());
                    if (data["status"].toLowerCase() === "completed" || data["status"].toLowerCase() === "fail") {

                        setCurrentStep(4);
                        clearInterval(job);
                    }
                }
            })
        }, 10 * 1000)
    }

    const handleClear = () => {
        setCurrentStep(0);
        setStep(1);
        setStatus("pending");
        setReceiptHash("")
        clearAmount();
    }

    return (
        <>

            <div className="modal fade" id="stepModal" tabIndex="-1" aria-labelledby="stepModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">

                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title text-dark w-100 text-center" id="stepModalLabel">BRIDGE PROCESS</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <BlockUi tag="div" blocking={showBlockUI}>
                            <div className="modal-body pt-4 pb-0 px-3">
                                <div className="bs-stepper w-100">
                                    <SwapLineStepTab step={step} currentStep={currentStep} status={status} />
                                    <SwapStepContent status={status}
                                        step={step} amount={amount} token={token} receiptHash={receiptHash}
                                        inputNetwork={inputNetwork} outputNetwork={outputNetwork} />

                                </div>
                            </div>
                            <div className="modal-footer justify-content-center py-4" style={{ border: 0 }}>
                                {
                                    step === 1 &&
                                    <Button className="btn btn-primary btn-lg px-5"
                                        onClick={() => { setStep(2); setCurrentStep(1) }}
                                    >
                                        Confirm
                                    </Button>
                                }
                                {
                                    step === 2 &&
                                    <Button className="btn btn-primary btn-lg px-5"
                                        onClick={handleApproveStep} >
                                        Approve
                                    </Button>
                                }
                                {
                                    step === 3 &&
                                    <Button className="btn btn-primary btn-lg px-5"
                                        onClick={async () => handleSwapClickStep()}
                                    >
                                        Submit
                                    </Button>
                                }
                                {
                                    currentStep === 4 &&
                                    <Button className="btn btn-primary btn-lg px-5" data-bs-dismiss="modal"
                                        onClick={handleClear}
                                    >
                                        Done
                                    </Button>
                                }
                            </div>
                        </BlockUi>
                    </div>
                </div>

            </div>

        </>
    )
}
export default StepModal;