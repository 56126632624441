import React, { useEffect, useState } from 'react';
import { DEFAULT_SELECT_TOKENS } from '../../constants';
import AssetSelected from '../AssetSelected';
import AssetSelectModal from '../AssetSelectModal';


export default function AssetPanel({ onChangeToken, tokenSelected, tokens }) {

    // console.log("tokenSelected==>", tokenSelected);
    const [token, setToken] = useState({});

    //set default token
    useEffect(() => {
        if (tokens.length > 0) {
            if (tokenSelected.symbol) {
                setToken(tokenSelected)
            } else {
                // const item = tokens.find(e => e.symbol == DEFAULT_SELECT_TOKENS.SYMBOL)
                setToken(tokens[0])
            }
        }

    }, [tokens, tokenSelected])
    return (
        <>
            <AssetSelected token={token} />

            <AssetSelectModal onClickSelect={onChangeToken} listTokens={tokens} selected={token.symbol} />
        </>
    );
}
