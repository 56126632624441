export const  MODE = process.env.REACT_APP_MODE || 'MAINNET';

export const EXPLORER = {
    TRX: {
        MAINNET:'https://tronscan.org',
        TESTNET:'https://nile.tronscan.org'
    },
    BSC:{
        MAINNET:'https://bscscan.com',
        TESTNET:'https://testnet.bscscan.com'
    },
    ETH:{
        MAINNET:'https://etherscan.io',
        TESTNET:'https://ropsten.etherscan.io'
    }
}

export const BACKEND_URI = {
    // MAINNET:'https://bridge.tronpad.network',   //
    MAINNET:'https://bridge.tronpad.network',  //https://bridge.tronpad.network
    TESTNET:'http://bridge.staging-tp.cloud'
}

export const IMAGE_URL = 'https://bspcpad.s3.ap-southeast-1.amazonaws.com/'


export const SWAP_SMART_CONTRACT = {
    BSC_TRX:{
        MAINNET:'0xF7CAA2f8412ad9329467435dE949183307724370', //product
        TESTNET:'0xfF18B93686Dc3133B0321B6BfFa1e525B9a52189' //testnet 
    },
   
    TRX_BSC:{
        MAINNET:'TTod5T5MgechYpRnTPDm54b4x42LWqsYCB', //product
        TESTNET:'TL3sSCm6uiwK1KpNWLpFcGcDZqHxjxVUhV'  //testnet
    },
    ETH_BSC:{
        MAINNET:'',
        TESTNET:''
    },
    BSC_ETH:{
        MAINNET:'',
        TESTNET:''
    },
    TRX_ETH:{
        MAINNET:'',
        TESTNET:''
    },
}

export const RPC = {
  BSC_NETWORK:{
      MAINNET:'https://bsc-dataseed.binance.org/',
      TESTNET:'https://data-seed-prebsc-1-s1.binance.org:8545/'
  },
  KCC_NETWORK:{
      MAINNET:'https://rpc-mainnet.kcc.network',
      TESTNET:'https://rpc-testnet.kcc.network'
  },
  TRON_NETWORK:{
    MAINNET:'https://api.trongrid.io',
    TESTNET:'https://api.trongrid.io'
}
}