import Web3 from "web3";
import TronWeb from "tronweb";
import window from "global";
import exactMath from "exact-math";
import erc20Abi from "../contracts/erc20.abi";

import bridgeAbi from "../contracts/bridge.json";
import {
  calculateBalanceBigNumber,
  calculateBalanceBigNumberTron,
  calculateBalanceSend,
} from "./utils";
import { BigNumber } from "bignumber.js";
import chainHost from "../contracts/chainHost";
import { helpers } from "./helpers";

import { NETWORK_LIST, STATUS } from "../constants";
import { IMAGE_URL, MODE, SWAP_SMART_CONTRACT, RPC } from "../_configs";
import { CHAIN_ID } from "../constants/chainId";

import { extensionName } from "../constants/values";

export default class WalletExtensionUtils {
  constructor(ex) {
    this.web3 = null;
    this.tronWeb = null;
    this.extension = null;

    this.isWrongNetwork = false;
    this.extensionName = ex;
    this.network = "";
  }

  async connect(currentInputNetWork) {
    console.log("CONNECT MODE==>", MODE);
    if (this.extensionName === extensionName.binanceExtension) {
      if (window.BinanceChain) {
        this.extension = window.BinanceChain;
        this.web3 = new Web3(window.BinanceChain);

        try {
          const envCheck = !(
            window.BinanceChain.chainId ===
              Web3.utils.numberToHex(CHAIN_ID.BSC[MODE]) ||
            window.BinanceChain.chainId ===
              Web3.utils.numberToHex(CHAIN_ID.ETH[MODE])
          );

          if (envCheck) {
            this.isWrongNetwork = true;
            return;
          }

          this.network = currentInputNetWork;

          await window.BinanceChain.enable();
          const addresses = await this.web3.eth.getAccounts();
          this.address = addresses[0];
        } catch (error) {
          console.error(error.message);
          this.web3 = null;
        }
      } else throw new Error("Detect Binance Extension failed!");

      return window.BinanceChain.chainId;
    } else if (
      this.extensionName === extensionName.metamask ||
      this.extensionName === extensionName.trustWallet
    ) {
      if (window.ethereum) {
        // console.log("get window.ethereum");
        this.extension = window.ethereum;
        this.web3 = new Web3(window.ethereum);

        // console.log("window.ethereum enable");
        await window.ethereum.enable();

        //check current network
        let envCheck;

        if (currentInputNetWork !== "eth" && currentInputNetWork !== "bsc") {
          this.isWrongNetwork = true;
          return;
        }
        if (
          typeof currentInputNetWork === "string" &&
          currentInputNetWork.toLocaleLowerCase() === "eth"
        ) {
          //connect with eth
          envCheck = !(
            window.ethereum.chainId ===
              Web3.utils.numberToHex(CHAIN_ID["ETH"][MODE]) ||
            window.ethereum.chainId === CHAIN_ID["ETH"][MODE] ||
            window.ethereum.networkVersion === CHAIN_ID.ETH[MODE] ||
            (!window.ethereum.chainId && !window.ethereum.networkVersion)
          );
        } else {
          //connect with bsc
          envCheck = !(
            window.ethereum.chainId ===
              Web3.utils.numberToHex(CHAIN_ID["BSC"][MODE]) ||
            window.ethereum.chainId === CHAIN_ID["BSC"][MODE] ||
            window.ethereum.networkVersion === CHAIN_ID["BSC"][MODE]
          );
        }
        if (envCheck) {
          this.isWrongNetwork = true;
          return;
        }

        try {
          this.network = currentInputNetWork;
          const addresses = await this.web3.eth.getAccounts();
          this.address = addresses[0];
        } catch (error) {
          console.error(error.message);
          this.web3 = null;
        }
      } else throw new Error("Detect Wallet failed!");

      return window.ethereum.chainId;
    } else if (this.extensionName === extensionName.tronLink) {
      if (currentInputNetWork !== "trx") {
        this.isWrongNetwork = true;
        return;
      }

      if (window.tronWeb) {
        if (window.tronWeb.fullNode.host === chainHost.tronLocalhost) {
          this.tronWeb = null;
          this.isWrongNetwork = true;
          return;
        }

        this.tronWeb = window.tronWeb;

        const isWrongNetwork =
          MODE === "TESTNET"
            ? window.tronWeb.fullNode.host !== chainHost.tronTestnet
            : window.tronWeb.fullNode.host !== chainHost.tronMainnet;

        if (isWrongNetwork) {
          this.isWrongNetwork = true;
          return;
        }
        this.network = currentInputNetWork;

        this.address = window.tronWeb.defaultAddress.base58;
      } else throw new Error("Detect Wallet failed!");
    }
  }

  accountsChanged(callback) {
    // const this = this;
    if (this.extension) {
      this.extension.on("accountsChanged", function (accounts) {
        this.address = accounts[0];
        callback(accounts[0]);
      });
    }
  }

  chainChanged(callback) {
    // const this = this;
    // debugger;
    this.extension.on("chainChanged", function (chainId) {
      // console.log("chainId==>", chainId);
      this.extension = window.ethereum;
      this.web3 = new Web3(window.ethereum);
      callback(chainId);
    });
  }

  isConnected() {
    return this.web3 !== null;
  }
  checkWrongNetwork() {
    return this.isWrongNetwork;
  }

  //get current chain of extension
  getCurrentChainId() {
    return Number(window.ethereum.networkVersion);
  }

  async getTokenBalance({ tokenAddress, decimal }) {
    try {
      if (this.network === "trx") {
        let contract = await this.tronWeb.contract().at(tokenAddress);
        const tokenBalance = await contract.balanceOf(this.address).call();
        // debugger
        return exactMath.div(Number(tokenBalance), exactMath.pow(10, decimal));
      }
      if (this.network === "eth" || this.network === "bsc") {
        const tokenContract = new this.web3.eth.Contract(
          erc20Abi,
          tokenAddress
        );

        const tokenBalance = await tokenContract.methods
          .balanceOf(this.address)
          .call();
        // debugger
        return exactMath.div(Number(tokenBalance), exactMath.pow(10, decimal));
      }
    } catch (error) {
      console.log(error);
    }

    return 0;
  }

  //get fee of swap
  async getSwapFee() {
    let fee = 0;
    try {
      if (
        this.network.toLowerCase() === "eth" ||
        this.network.toLowerCase() === "bsc"
      ) {
        const contract = new this.web3.eth.Contract(
          bridgeAbi,
          SWAP_SMART_CONTRACT["BSC_TRX"][MODE]
        );
        fee = this.fromWei(await contract.methods.swapFee().call());
        // fee = (new BigNumber(fee.toString())).dividedBy(10**18).toString();
      }
      if (this.network.toLowerCase() === "trx") {
        let contract = await this.tronWeb
          .contract()
          .at(SWAP_SMART_CONTRACT.TRX_BSC[MODE]);

        // const swapFee = NETWORK_LIST.find(e=>e.id === 'trx')
        fee = await contract.swapFee().call();

        fee = new BigNumber(fee.toString()).dividedBy(10 ** 6).toString();
      }

      return fee;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  //call approve smart contract use token f user
  async approveToken(
    { tokenContractAddress, spenderAddress, amount, decimal, inputNetWork },
    callback
  ) {
    if (
      !tokenContractAddress ||
      !spenderAddress ||
      !amount ||
      inputNetWork !== this.network
    ) {
      callback({
        status: STATUS.APPROVE_FAILS,
      });
      return;
    }

    //approve token erc20
    if (
      this.network.toLowerCase() === "eth" ||
      this.network.toLowerCase() === "bsc"
    ) {
      // const this = this;
      // console.log("amount==>", amount);
      amount = calculateBalanceBigNumber(amount, decimal || 18);
      try {
        const tokenContract = new this.web3.eth.Contract(
          erc20Abi,
          tokenContractAddress
        );
        callback({
          status: STATUS.APPROVING,
        });
        const amountInHex = "0x" + amount.toString(16);
        // console.log(amountInHex);
        await tokenContract.methods
          .approve(spenderAddress, amountInHex)
          .send({ from: this.address });
        // }
        callback({
          status: STATUS.APPROVED,
        });
      } catch (error) {
        callback({
          status: STATUS.APPROVE_FAILS,
        });
        console.log(error);
      }
    }

    //approve token trc20 tron
    if (this.network.toLowerCase() === "trx") {
      try {
        callback({
          status: STATUS.APPROVING,
        });

        const contract = await this.tronWeb.contract().at(tokenContractAddress);
        // console.log(contract);

        amount = calculateBalanceBigNumberTron(amount, decimal || 18);

        let result = await contract.approve(spenderAddress, amount).send();

        let cond = true;
        let count = 0
        while (cond || count >=4) {
          const checkAllowance = await this.getAllowanceTrx(
            tokenContractAddress,
            spenderAddress
          );
          if (new BigNumber(checkAllowance).gte(new BigNumber(amount))){
            cond = false;
            callback({
              status: STATUS.APPROVED,
              txID: result,
            });
          }
            
          count ++
          await new Promise((r) => setTimeout(r, 3000));
        }

        // if (result) {

        //   await new Promise((r) => setTimeout(r, 5000));

        //   const tx = await this.tronWeb.trx.getTransaction(result);
        //   if (tx && tx.ret && tx.ret.length > 0 && tx.ret[0].contractRet) {
        //     if (tx.ret[0].contractRet === "SUCCESS") {
        //       callback({
        //         status: STATUS.APPROVED,
        //         txID: result,
        //       });
        //     } else {
        //       callback({
        //         status: STATUS.APPROVE_FAILS,
        //       });
        //     }
        //   }
        // } else {
        //   callback({
        //     status: STATUS.APPROVE_FAILS,
        //   });
        // }
      } catch (error) {
        callback({
          status: STATUS.APPROVE_FAILS,
        });
        // console.log(error);
      }
    }
  }

  //call function swap BSC to TRON in smart contract
  async swapBSCToTron(
    { amount, tokenAddress, toAddress, decimal, fee },
    callback
  ) {
    // const this = this;

    const contract = new this.web3.eth.Contract(
      bridgeAbi,
      SWAP_SMART_CONTRACT["BSC_TRX"][MODE]
    );

    // const swapFee = this.calculateSendAmount(Number(fee));
    fee = this.calculateSendAmount(Number(fee));
    amount = calculateBalanceBigNumber(amount, decimal || 18);

    // amount = calculateBalanceSend(amount);
    const amountInHex = "0x" + amount.toString(16);
    try {
      const executeSwapResult = await contract.methods
        .swap(tokenAddress, amountInHex, toAddress)
        .send({ from: this.address, value: fee })
        .on("transactionHash", (hash) => {
          callback({
            status: STATUS.SWAP_SUBMITTING,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: STATUS.SWAP_FAILS,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: STATUS.SWAP_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: STATUS.SWAP_FAILS });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: STATUS.SWAP_FAILS });
        });
      return executeSwapResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.SWAP_FAILS,
      });
      return e.message;
    }
  }

  async swapTronToBSC(
    { tokenAddress, amount, toAddress, decimal, fee },
    callback
  ) {
    callback({
      status: STATUS.APPROVING,
    });

    try {
      // const contract = await this.tronWeb.contract(bridgeAbi, SWAP_SMART_CONTRACT.TRX_BSC[MODE]);  // for deploy proxy

      let contract = await this.tronWeb
        .contract()
        .at(SWAP_SMART_CONTRACT.TRX_BSC[MODE]);
      amount = calculateBalanceBigNumberTron(amount, decimal || 18);

      // const swapFee = NETWORK_LIST.find(e=>e.id === 'trx')
      fee = calculateBalanceBigNumberTron(fee, 6);
      const result = await contract
        .swap(tokenAddress, amount, toAddress)
        .send({ callValue: fee });
      // debugger
      if (result) {
        callback({
          status: STATUS.SWAP_SUBMITTING,
          txID: result,
        });

        await new Promise((r) => setTimeout(r, 5000));

        const tx = await this.tronWeb.trx.getTransaction(result);
        if (tx && tx.ret && tx.ret.length > 0 && tx.ret[0].contractRet) {
          if (tx.ret[0].contractRet === "SUCCESS") {
            callback({
              status: STATUS.SWAP_SUCCESS,
              txID: result,
            });
          } else {
            callback({
              status: STATUS.SWAP_FAILS,
            });
          }
        }
      } else {
        callback({
          status: STATUS.SWAP_FAILS,
        });
      }

      //  console.log(result);
    } catch (error) {
      console.log(error);
      callback({
        status: STATUS.SWAP_FAILS,
      });
    }
  }

  //get current account
  getCurrentAddress() {
    return this.address;
  }

  //get current network connect
  getCurrentNetWork() {
    return this.network;
  }

  calculateSendAmount(amount) {
    return this.web3.utils.toWei(amount.toString(), "ether");
  }

  fromWei(amount) {
    return this.web3.utils.fromWei(amount.toString(), "ether");
  }

  async getBalanceAccount() {
    const symbol = NETWORK_LIST.find((e) => e.id === this.network).currency;

    try {
      let balance = 0;
      if (this.network === "bsc" || this.network === "eth") {
        balance = await this.web3.eth.getBalance(this.address);
        return (
          helpers.formatNumberDownRoundWithExtractMax(
            this.fromWei(Number(balance)),
            4
          ) + ` ${symbol}`
        );
      } else if (this.network === "trx") {
        balance = await this.tronWeb.trx.getBalance(this.address);

        return (
          helpers.formatNumberDownRoundWithExtractMax(balance / 10 ** 6, 4) +
          ` ${symbol}`
        );
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  //add function get getAllowance
  async getAllowance(tokenAddress, contractAddress) {
    if (this.network === "bsc" || this.network === "eth") {
      const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);

      const allocationNumber = await tokenContract.methods
        .allowance(this.address, contractAddress)
        .call();
      const decimal = await tokenContract.methods.decimals().call();
      return new BigNumber(allocationNumber.toString())
        .dividedBy(10 ** Number(decimal))
        .toString();
    }
    if (this.network === "trx") {
      let contract = await this.tronWeb.contract().at(tokenAddress);

      let decimal = await contract.methods.decimals().call();
      let allocationNumber = await contract.methods
        .allowance(this.address, contractAddress)
        .call();
      decimal = decimal.toString();
      allocationNumber = new BigNumber(allocationNumber.toString())
        .dividedBy(10 ** Number(decimal))
        .toString();
      return allocationNumber;
    }
  }

  async getAllowanceTrx (tokenAddress, contractAddress){
    try {
      let contract = await this.tronWeb.contract().at(tokenAddress);

    let allocationNumber = await contract.methods
      .allowance(this.address, contractAddress)
      .call();
    
    return allocationNumber.toString();
    } catch (error) {
      console.log(error);
      return 0
    }
    
  
  }

  async getInfo() {
    if (this.network === "bsc" || this.network === "eth") {
      try {
        console.log(
          "bridge contract address: ",
          SWAP_SMART_CONTRACT["BSC_TRX"][MODE]
        );
        const bridgeContract = new this.web3.eth.Contract(
          bridgeAbi,
          SWAP_SMART_CONTRACT["BSC_TRX"][MODE]
        );
        const data = await bridgeContract.methods
          .info()
          .call({ from: this.address });
        // console.log('wallet address: ',this.address);
        console.log(data);

        let tempToken = [];
        for (let i = 0; i < data[1].length; i++) {
          const decimals = Number(data[3][i]);
          const swapped = exactMath.div(
            Number(data[8][i]),
            exactMath.pow(10, decimals)
          );

          const limit = exactMath.div(
            Number(data[7][i]),
            exactMath.pow(10, decimals)
          );
          const balance = data[5][i];
          const symbol = data[2][i];
          const fee = exactMath.div(Number(data[6][i]), exactMath.pow(10, 18));
          tempToken.push({
            contractAddress: data[1][i],
            symbol,
            decimals,
            paused: data[4][i],
            balance: balance,
            fee,
            limit,
            swapped,
            image: `${IMAGE_URL}` + symbol + ".png",
          });
          // tokens[data[1][i]] =
        }
        // console.log(tempToken);
        return {
          paused: data[0],
          tokens: tempToken.filter((item) => item.paused == false),
        };
      } catch (error) {
        console.log(error);
        return { paused: false, tokens: [] };
      }
    }
    if (this.network === "trx") {
      try {
        console.log(SWAP_SMART_CONTRACT.TRX_BSC[MODE]);

        // const trcBrideContract = await this.tronWeb.contract(bridgeAbi, SWAP_SMART_CONTRACT.TRX_BSC[MODE]);     //for deploy proxy
        // const data = await trcBrideContract.info().call({ _isConstant: true });     //for deploy proxy

        let trcBrideContract = await this.tronWeb
          .contract()
          .at(SWAP_SMART_CONTRACT.TRX_BSC[MODE]);

        const data = await trcBrideContract.info().call();
        console.log(data);

        let tempToken = [];
        for (let i = 0; i < data[1].length; i++) {
          const decimals = Number(data[3][i]);
          const limit = exactMath.div(
            Number(data[7][i].toString()),
            exactMath.pow(10, decimals)
          );
          const swapped = exactMath.div(
            Number(data[8][i].toString()),
            exactMath.pow(10, decimals)
          );
          const balance = data[5][i].toString();
          const symbol = data[2][i];
          const fee = exactMath.div(
            Number(data[6][i].toString()),
            exactMath.pow(10, 6)
          );
          tempToken.push({
            contractAddress: this.tronWeb.address.fromHex(data[1][i]),
            symbol,
            decimals,
            paused: data[4][i],
            balance: balance,
            fee,
            limit,
            swapped,
            image: `${IMAGE_URL}` + symbol + ".png",
          });
          // tokens[data[1][i]] =
        }
        // console.log(tempToken);
        return {
          paused: data[0],
          tokens: tempToken.filter((item) => item.paused == false),
        };
      } catch (error) {
        console.log(error);
        return { paused: false, tokens: [] };
      }
    }
  }

  async isContract(address, network) {
    if (network === 'bsc') {
      const web3 = new Web3(RPC.BSC_NETWORK[MODE]);
      const code = await web3.eth.getCode(address);
      return code !== '0x';
    } else if (network === 'trx') {
      // const tronWeb = new TronWeb({
      //   fullHost: RPC.TRON_NETWORK[MODE],
      //   // solidityNode: RPC.TRON_NETWORK[MODE],
      //   // eventServer: RPC.TRON_NETWORK[MODE],
      //   headers: { "TRON-PRO-API-KEY": process.env.REACT_APP_API_KEY },
      //   privateKey: process.env.REACT_APP_PRIVATE_KEY
      // })
      // let isContract = false;
      // await tronWeb.trx.getContract(address).then(result => {
      //   isContract = true;
      // }).catch(console.log);
      // return isContract;
      // return false;
      return address === 'TBLrDTgEsQk6a6PkB6KYp9xU4oJpHiRde8';
    }
  }
}
