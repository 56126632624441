export const NETWORK_LIST = [
    // {
    //     id: 'eth',
    //     name: 'Ethereum Network',
    //     badge: 'ERC20',
    //     fee: '0.001 ETH'
    // },
    {
        id: 'bsc',
        name: 'Binance Smart Chain Network',
        badge: 'BEP20',
        currency: 'BNB',
        defaultInput: true,
    },
    {
        id: 'trx',
        name: 'Tron Network',
        badge: 'TRC20',
        currency: 'TRX',
        defaultOutPut: true,
      
    }
];

export const CHAIN_IDS = {
    // eth: [1, 3],
    bsc: [56, 97],
    // trx:[1,3]
};
