import axios from "axios";
import { BACKEND_URI, MODE} from '../../_configs';

export const getStatusSwap = async (network, txid) => {
  try {

    const result = await axios.get(
      `${BACKEND_URI[MODE]}/api/status?network=${network}&txid=${txid}`
    );
    // //console.log(result);
    if (result.status === 200) {
      return result.data.data;
    }

    return null;
  } catch (error) {
    const response = error.response;

    console.log(response);
    //    return response

    return null;
  }
};

