import React from 'react';
import ConnectWalletModal from '../ConnectWalletModal';
import WalletModal from '../WalletModal';
import packageJson from '../../../../package.json';


export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>

      <div className="pp-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 text-muted mt-md-0 mt-3 text-md-start text-center">Copyright © {currentYear}. All Rights Reserved by TRONPAD</div>
            <div className="col-md-4 text-center text-muted">Version {packageJson.version}</div>
            <div className="col-md-4">
              <a className="text-warning text-decoration-none d-flex align-items-center justify-content-md-end justify-content-center" href="https://bluezilla.jitbit.com/helpdesk/" target="_blank">
                {/* <i className="mdi mdi-at me-1 font-20"></i> */}
                <span>Contact Support</span>
              </a>
            </div>
            {/* <div className="text-right pp-footer-link">
              <div class="p-footer-social">
                <a href="mailto:support@glitch.finance"><img  src="https://img.icons8.com/fluent/2x/email-sign.png"/></a>
                <a target="_blank" href="https://t.me/glitchbridge"><img src="https://img.icons8.com/color/2x/telegram-app.png"/></a>
              </div>
             
            </div> */}
          </div>
          {/* </div> */}
        </div>
      </div>
      <ConnectWalletModal />

      <WalletModal />

    </>
  );
}